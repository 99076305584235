@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: "Plus Jakarta Sans", sans-serif;
  letter-spacing: 0.01em;
}

/* To remove click event on touch screens*/
a,
* {
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

/* To keep the UI from breaking */
.container {
  max-width: 1200px;
  padding: 0 2rem;
  position: relative;
  margin: auto;
}

/* Menu Fixed */
.fixed-menu {
  min-width: 100%;
  position: fixed;
}

/* Carousel Bullet pagination */
.swiper-pagination-bullet {
  border: 1px solid rgb(178, 178, 178) !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
  opacity: 1 !important;
}

/* Carousel Bullet pagination while active */
.swiper-pagination-bullet-active {
  background: rgb(178, 178, 178) !important;
}

/* Non clickable images */
img {
  pointer-events: none;
}

/* Swiper buttons */
.swiper-button-prev,
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next,
.swiper-button-next.swiper-button-disabled {
  z-index: 10 !important;
  pointer-events: all !important;
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 40% !important;
}

.swiper-button-prev:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 25px !important;
}

/* Phone number with countries */
.react-tel-input .form-control {
  width: 100% !important;
  height: 58px !important;
  padding-left: 60px !important;
}

.react-tel-input .selected-flag {
  width: 45px !important;
}

/* Animation for submenu dropdown */
.submenu {
  max-height: 0px;
  overflow-y: hidden;
  overflow-x: auto;
  transition: max-height 150ms ease-in-out;
}

.submenu.active {
  max-height: 2000px; /* Adjust the max-height to your needs */
  transition: max-height 1000ms ease-in-out;
}

.subsubmenu {
  max-width: 0px;
  overflow: hidden;
  transition: max-width 0ms ease-in-out;
}

.subsubmenu.active {
  max-width: 1000px; /* Adjust the max-height to your needs */
  transition: max-width 1000ms ease-in-out;
}

/* Multi Select */
.react-select__control,
.react-select__menu {
  padding: 0.5rem;
}

input {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1420px;
  }
}

/* bot */
.bot {
  /* max-height: 0px; */
  /* max-width: 0px; */
  overflow: hidden;
  transform: scale(0);
  transition: all 200ms ease-in-out;
}

.bot.active {
  /* max-height: 2000px; */
  /* max-width: 1500px; */
  overflow: hidden;
  transform: scale(1);
  transform-origin: bottom;
  transition: all 300ms ease-in-out;
}

/* Chat bot styles override */
.mkosBTNContainer._widgetRight .mkosBTNFrame {
  width: 95px !important;
  height: 95px !important;
}

.mkosBTNContainer .mkosBTNFrame._small {
  width: 95px !important;
  height: 95px !important;
}

.rippleEffectHold {
  display: none !important;
}

.robosOnOuter .robosOnIcon {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.video,
.iframe {
  clip-path: inset(1px 1px);
}

.shadows {
  box-shadow: 61px 40px 52px -41px rgba(0, 0, 0, 0.1);
}

/* Dot Flashing */

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d5d5d5;
  color: #d5d5d5;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d5d5d5;
  color: #d5d5d5;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d5d5d5;
  color: #d5d5d5;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #d5d5d5;
  }
  50%,
  100% {
    background-color: #eee;
  }
}

/* Bot Scrollbar */
.chat::-webkit-scrollbar {
  /* display: none; */
}

.response ul {
  list-style: disc;
  margin: 1rem 0 1rem 0;
  padding-left: 0.8rem;
}

.response ul li {
  margin: 0.5rem 0 0.5rem 0;
}

.response a {
  text-decoration: underline;
  color: blue;
}

.temporary-bounce {
  -webkit-animation-iteration-count: 1.5;
  animation-iteration-count: 1.5;
}

/* width */
.custom_scroll::-webkit-scrollbar {
  width: 7px !important;
}

/* Track */
.custom_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom_scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
